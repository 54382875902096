/**
 * main.ts
 * contains the whole logic of the project
 * uses classes that are defined
 * in elements,components or layouts
**/

import { Base } from "../../1_base/base";
import { Sidebar } from "../../3_layout/page/sidebar/sidebar";
import { ContentCols } from "../../4_components/content-cols/content-cols";
import { Flyout } from "../../3_layout/page/flyout/flyout";
import { FlyoutNav } from "../../3_layout/nav/flyout-nav";
import { CookieMessage } from "../../3_layout/page/cookie-message/cookie-message";
import { Panel } from "../../3_layout/page/panel/panel";
import { Panelnav } from "../../3_layout/nav/panel-nav";
import { Landingpagenavigation } from "../../3_layout/nav/landingpagenavigation";
import { toTop } from "../../3_layout/page/to-top/to-top";
import { Header } from "../../3_layout/header/header";
import { pageLoader } from "../../3_layout/page/pageloader/pageloader";
import { Hero } from "../../3_layout/content/hero/hero";
import { Slider } from "../../4_components/slider/slider";
import { Filter } from "../../2_elements/filter/filter";

var locations = null;


/**
 * Document-ready function
**/

$(document).ready(function() {
    /**
     * Basics
     * general scripts required for multiple components and elements
    **/
    let base = new Base();

    /**
     * Sidebar
    **/
    let sidebar = new Sidebar();

    /**
     * Content-Cols - same Header height script
    **/
   let contentcols = new ContentCols();

    /**
     * Flyout
    **/
   let flyout = new Flyout();

   /**
    * Flyout-nav
   **/
  let flyoutNav = new FlyoutNav(flyout);

    /**
     * Cookie message
    **/
   let cookieMessage = new CookieMessage();

   /**
     * Forms
    **/
    // let form = new Formfloat();

    /**
     * Panel
    **/
   let panel = new Panel();

   /**
    * Navigation
    * Parameters:
    * panel: Instance of panel
    * accordionMode: Close already open menu tabs
    * openCurrent: open active menu tab on load
    * animationDuration
    * overviewpagesPossible: Possiblity to open a menu point directly
   **/
   let panelnav = new Panelnav(panel, true, true, 500, false);

    /**
     * Landingpagenavigation
     * Parameters:
     * animationDuration
    **/
    if($('.js-l-body').hasClass('js-l-body--landingpage')) {
        let landingpagenavigation = new Landingpagenavigation();
    }

    /**
     * To-Top-Button
     * Parameters:
     * animationDuration
    **/
    let totop = new toTop();

    /**
     * Header
    **/
   let header = new Header();

    /**
     * Pageloader
     * Parameters:
     * maximumTimeVisible
    **/
    let pageloader = new pageLoader();

    /**
     * Hero component
     * Parameters:
     * animationDuration
     * scrollFactor
    **/
    let hero = new Hero(600,0.7);

    /**
     * Slider
     * Parameters:
     * maximumTimeVisible
    **/
    let slider = new Slider();

    /* Watchlist */
    // let watchlist = new Watchlist();

    /**
     * Filter for tables
    **/
    let filter = new Filter();

    /**
     * Change hero-design-nav gradient background color in mobile
    **/
   $('.collapse').collapse();

    $('.l-hero__text').find('.e-button').click(function(){
      var gradient = $(this).parent().siblings('.l-hero__gradient');
      gradient.css('background-color', 'rgba(177,47,44,' + 0.8 +')');
    });
});